import {Offcanvas, Tooltip} from "bootstrap";

const applicationServerPublicKey = fbBlogNotification.publicKey;
const serviceWorkerPath = fbBlogNotification.swPath;
let hasSubscriptionError = false;
let isSubscribed = false;
let endpoint = null;
let swRegistration = null;
let pushNotificationTooltip = null;
let activeCategories = null;
let wasShown = false;
let storedData = JSON.parse(localStorage.getItem('pushNotification'));
const classNameFaBell = 'fa-bell';
const classNameFaBellSlash = 'fa-bell-slash';
const titleActivate = 'Für Benachrichtigungen anmelden';
const titleDeactivate = 'Benachrichtigungen deaktivieren';
const pushNotificationButtton = document.getElementById('pushnotification-button');
const pushNotificationButttonIcon = document.getElementById('pushnotification-button-icon');
const subscribeButton = document.getElementById('subscribe-notification');
const unsubscribeButton = document.getElementById('notification-unsubscribe-btn');
const notificationOffcanvasElement = document.getElementById('pushnotification-offcanvas');
const notificationOffcanvas = new Offcanvas(notificationOffcanvasElement);
const pushcategoriesOffcanvasElement = document.getElementById('pushcategories-offcanvas');
const pushcategoriesOffcanvas = new Offcanvas(pushcategoriesOffcanvasElement);
const subscribeCategoriesBtn = document.getElementById('subscribe-categories-btn');
const pushsettingsOffcanvas = new Offcanvas(document.getElementById('offcanvas-notification'));
const updateCategoriesBtn = document.getElementById('notification-update-btn');

function urlB64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
		.replace(/-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

function initializeUI() {
	if (storedData && storedData.wasShown) {
		wasShown = storedData.wasShown;
	}

	subscribeButton.addEventListener('click', function () {
		subscribeUser();
	});

	// Set the initial subscription value
	swRegistration.pushManager.getSubscription()
		.then(function (subscription) {
			isSubscribed = !(subscription === null);
			pushNotificationButtton.classList.add('show');
			if (isSubscribed) {
				endpoint = subscription.endpoint;
				pushNotificationButttonIcon.classList.add(classNameFaBellSlash);
				pushNotificationButtton.title = titleDeactivate;
				// localStorage.setItem('pushNotification', JSON.stringify({wasShown: true}));
				// console.log('User IS subscribed.');
			} else {
				pushNotificationButttonIcon.classList.add(classNameFaBell);
				pushNotificationButtton.title = titleActivate;
				if (!wasShown) {
					setTimeout(() => {
						notificationOffcanvas.show();
						pushNotificationTooltip.hide();
					}, 4000);
				}
				// console.log('User is NOT subscribed.');
			}
			pushNotificationTooltip = new Tooltip(pushNotificationButtton, {placement: 'right'});
		});

	pushNotificationButtton.addEventListener('click', () => {
		if (isSubscribed) {
			showPushNotificationSettings();
		} else {
			notificationOffcanvas.show();
			pushNotificationTooltip.hide();
		}
	});

	subscribeCategoriesBtn.addEventListener('click', () => {
		const modalInputFields = document.querySelectorAll('[id^="mo-category__"]');
		const data = {
			endpoint: endpoint,
			categories: [],
		};
		for (const modalInputField of modalInputFields) {
			if (modalInputField.checked === true) {
				data.categories.push(modalInputField.value);
			}
		}
		setCategoriesOnServer(data);
		pushcategoriesOffcanvas.hide();
	});

	updateCategoriesBtn.addEventListener('click', () => {
		const offcanvasInputFields = document.querySelectorAll('[id^="oc-category__"]');
		const data = {
			endpoint: endpoint,
			categories: [],
		};
		for (const offcanvasInputField of offcanvasInputFields) {
			if (offcanvasInputField.checked === true) {
				data.categories.push(offcanvasInputField.value);
			}
		}
		setCategoriesOnServer(data);
		pushsettingsOffcanvas.hide();
	});

	unsubscribeButton.addEventListener('click', () => {
		unsubscribeUser();
		pushsettingsOffcanvas.hide();
	});

	notificationOffcanvasElement.addEventListener('hidden.bs.offcanvas', () => {
		localStorage.setItem('pushNotification', JSON.stringify({wasShown: true}));
	});
}

function subscribeUser() {
	// console.log('subscripe');
	const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
	swRegistration.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: applicationServerKey
	})
		.then(function (subscription) {
			// console.log('User is subscribed.');
			updateSubscriptionOnServer(subscription);
			isSubscribed = true;
			endpoint = subscription.endpoint;
		})
		.then(function () {
			pushNotificationButttonIcon.classList.remove(classNameFaBell);
			pushNotificationButttonIcon.classList.add(classNameFaBellSlash);
			pushNotificationButtton.title = titleDeactivate;
			pushNotificationButtton.dataset.bsOriginalTitle = titleDeactivate;
			notificationOffcanvas.hide();
		})
		.then(function () {
			pushcategoriesOffcanvas.show();
			pushNotificationTooltip.setContent({'.tooltip-inner': titleDeactivate})
			pushNotificationTooltip.hide();
		})
		// .catch(function (err) {
		// 	console.log('Failed to subscribe the user: ', err);
		// });
}

function unsubscribeUser() {
	swRegistration.pushManager.getSubscription()
		.then(function (subscription) {
			if (subscription) {
				endpoint = subscription.endpoint;
				return subscription.unsubscribe();
			}
		})
		// .catch(function (error) {
		// 	console.log('Error unsubscribing', error);
		// })
		.then(function () {
			// console.log(endpoint);
			updateSubscriptionOnServer(endpoint);

			// console.log('User is unsubscribed.');
			isSubscribed = false;
		});
	pushNotificationButttonIcon.classList.remove(classNameFaBellSlash);
	pushNotificationButttonIcon.classList.add(classNameFaBell);
	pushNotificationButtton.title = titleActivate;
	pushNotificationButtton.dataset.bsOriginalTitle = titleActivate;
	pushNotificationTooltip.setContent({'.tooltip-inner': titleActivate});
	localStorage.setItem('pushNotification', JSON.stringify({wasShown: true}));
}

async function updateSubscriptionOnServer(subscription) {
	let subscriptionObject = null;
	if (typeof subscription === 'object') {
		const subscriptionJSObject = JSON.parse(JSON.stringify(subscription));
		subscriptionObject = {
			subscription: {
				'endpoint': subscriptionJSObject.endpoint,
				'auth': subscriptionJSObject.keys.auth,
				'p256dh': subscriptionJSObject.keys.p256dh,
			}
		};
		if (fbBlogNotification.subscribePath) {
			const response = await fetch(fbBlogNotification.subscribePath, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(subscriptionObject)
			})
				// .then(data => {
				// 	console.log(data);
				// });
		}
	} else if (typeof subscription === 'string') {
		subscriptionObject = {
			subscription: {
				'endpoint': subscription,
			}
		};
		if (fbBlogNotification.unsubscribePath) {
			const response = await fetch(fbBlogNotification.unsubscribePath, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(subscriptionObject)
			})
				// .then(data => {
				// 	console.log(data);
				// });
		}
	}
}

if (
	'serviceWorker' in navigator
	&& 'PushManager' in window
	&& applicationServerPublicKey !== ''
	&& serviceWorkerPath !== ''
) {
	// console.log('Service Worker and Push is supported');

	navigator.serviceWorker.register(serviceWorkerPath)
		.then(function (swReg) {
			// console.log('Service Worker is registered', swReg);

			swRegistration = swReg;
			initializeUI();
		})
		.catch(function (error) {
			// console.error('Service Worker Error', error);
		});
} else {
	// console.warn('Push messaging is not supported');
}

export function togglePushNotificationButton() {
	if (pushNotificationButtton) {
		const scrollPostion = document.documentElement.scrollTop;
		const clientHeight = document.documentElement.clientHeight;
		const scrollPositionBottom = scrollPostion + clientHeight;
		const footerTop = document.querySelector('footer').offsetTop;
		const footerHeight = document.querySelector('footer').offsetHeight;
		const marginButton = 20;
		if (scrollPositionBottom > footerTop) {
			pushNotificationButtton.style.bottom = (footerHeight + marginButton) + 'px';
		} else {
			pushNotificationButtton.style.bottom = marginButton + 'px';
		}
	}
}

function showPushNotificationSettings() {
	const data = {
		endpoint: endpoint,
	};
	if (activeCategories === null) {
		getCategoriesOnServer(data)
			.then(categories => {
				updateCategories(categories);
			})
			.then(() => {
				if (hasSubscriptionError) {
					swRegistration.pushManager.getSubscription()
						.then(subscribtion => {
							updateSubscriptionOnServer(subscribtion);
							pushsettingsOffcanvas.show();
							pushNotificationTooltip.hide();
						});
				} else {
					pushsettingsOffcanvas.show();
					pushNotificationTooltip.hide();
				}
			});
	} else {
		pushsettingsOffcanvas.show();
		pushNotificationTooltip.hide();
	}
}

async function setCategoriesOnServer(data) {
	const subscriptionObject = {
		subscription: data
	};
	if (fbBlogNotification.setCategoriesPath) {
		await fetch(fbBlogNotification.setCategoriesPath, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(subscriptionObject),
		})
			.then((response) => {
				return response.json();
			})
			.then(data => {
				updateCategories(data.categories);
			});
	}
}


async function getCategoriesOnServer(data) {
	hasSubscriptionError = false;
	const subscriptionObject = {
		subscription: data
	};
	if (fbBlogNotification.getCategoriesPath) {
		return await fetch(fbBlogNotification.getCategoriesPath, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(subscriptionObject),
		})
			.then((response) => {
				return response.json();
			})
			.then(data => {
				if (data.readed === false) {
					hasSubscriptionError = true;
				}
				return data.categories;
			});
	}
}

function updateCategories(categories) {
	for (const category of categories) {
		const ocCheckbox = document.querySelector('[id^="oc-category__' + category + '"]');
		ocCheckbox.checked = true;
	}
	activeCategories = categories;
}

export function setOffcanvasClass() {
	if (window.innerWidth > 991) {
		notificationOffcanvasElement.classList.remove('offcanvas-bottom');
		notificationOffcanvasElement.classList.add('offcanvas-top');
		pushcategoriesOffcanvasElement.classList.remove('offcanvas-bottom');
		pushcategoriesOffcanvasElement.classList.add('offcanvas-top');
	} else {
		notificationOffcanvasElement.classList.remove('offcanvas-top');
		notificationOffcanvasElement.classList.add('offcanvas-bottom');
		pushcategoriesOffcanvasElement.classList.remove('offcanvas-top');
		pushcategoriesOffcanvasElement.classList.add('offcanvas-bottom');
	}
}
