let checklistInputs = null;
let inputsChecked = [];
const cookieName = 'blog-checklist';
const classNameChecked = 'fb-checkbox__checked';

export function initChecklist() {
	readChecklistFromLocalstorage();
	checklistInputs = document.querySelectorAll('.fb-checkbox input');
	for (const checklistInput of checklistInputs) {
		if (inputsChecked && inputsChecked.includes(checklistInput.id)) {
			checklistInput.checked = true;
			checklistInput.parentElement.classList.add(classNameChecked);
		}
		checklistInput.addEventListener('change', handleClick);
	}
}

function handleClick(e) {
	e.preventDefault();
	const targetElement = e.target;
	const parentElement = targetElement.parentElement;
	if (targetElement.checked) {
		parentElement.classList.add(classNameChecked);
		addCheckedInput(targetElement.id);
	} else {
		parentElement.classList.remove(classNameChecked);
		removeCheckedInput(targetElement.id);
	}
}

function addCheckedInput(uuid) {
	inputsChecked.push(uuid);
	setChecklistToLocalstorage();
}

function removeCheckedInput(uuid) {
	inputsChecked = inputsChecked.filter(item => item !== uuid);
	setChecklistToLocalstorage();
}

function setChecklistToLocalstorage() {
	localStorage.setItem(cookieName, JSON.stringify(inputsChecked));
}

function readChecklistFromLocalstorage() {
	const localdata = JSON.parse(localStorage.getItem(cookieName));
	if (localdata) {
		inputsChecked = localdata;
	}
}
