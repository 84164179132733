var youtubeContainers = document.querySelectorAll('.neos-nodetypes-youtube-preview');

for (var i = 0; i < youtubeContainers.length; i++) {
    youtubeContainers[i].addEventListener('click', loadVideoIframe)
}

function loadVideoIframe() {
    var settings = this.dataset;
    var iframeElement = document.createElement('iframe');
    iframeElement.setAttribute('src', settings.videoUrl + '?autoplay=0');
    iframeElement.setAttribute('width', settings.videoWidth);
    iframeElement.setAttribute('height', settings.videoHeight);
    iframeElement.setAttribute('frameborder', 0);
    iframeElement.setAttribute('allowfullscreen', 'allowfullscreen');
    iframeElement.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture')
    this.innerHTML = '';
    this.appendChild(iframeElement);
    this.removeEventListener('click', loadVideoIframe);
}
