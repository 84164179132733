import {Toast} from "bootstrap";

const postLikeButton = document.getElementById('post-like-button');
const postLikeButtonTop = document.getElementById('post-like-button-top');
const loadNewPostsButton = document.getElementById('load-newposts-button');

if (postLikeButton) {
    postLikeButton.addEventListener('click', createLike);
}

if (postLikeButtonTop) {
	postLikeButtonTop.addEventListener('click', createLike);
}

if (loadNewPostsButton) {
    loadNewPostsButton.addEventListener('click', (e) => {
        handleNewPosts(e);
    });
}

// Send request for counting views of posts
export async function createView() {
    if (typeof(fb_data) !== 'undefined' && typeof(fb_data.createViewUrl) === 'string' && fb_data.createViewUrl !== '') {
        await fetch(fb_data.createViewUrl, {method: 'POST'})
            .then(response => response.json());
    }
}

// Send request for counting views of posts
async function createLike() {
    if (typeof(fb_data) !== 'undefined' && typeof(fb_data.createLikeUrl) === 'string' && fb_data.createLikeUrl !== '') {
        const toastElList = [].slice.call(document.querySelectorAll('.toast'))
        const toastList = toastElList.map(function(toastEl) {
            // Creates an array of toasts (it only initializes them)
            return new Toast(toastEl) // No need for options; use the default options
        });
        postLikeButton.setAttribute('disabled', 'true');
				postLikeButtonTop.removeEventListener('click', createLike);
        postLikeButtonTop.classList.remove('cursor-pointer');
        toastList.forEach(toast => toast.show());
        await fetch(fb_data.createLikeUrl, {method: 'POST'})
            .then(response => response.json())
            .then(data => {
                const likeElement = document.getElementById('likes');
                if (likeElement) {
                    likeElement.innerHTML = data;
                }
                if (data > 0) {
                    const heartElements = document.querySelectorAll('#likes-heart');
                    for (let i = 0; i < heartElements.length; i++) {
                        if (!heartElements[i].classList.contains('heart-full')) {
                            heartElements[i].classList.add('heart-full');
                        }
                    }
                }
            });
    }
}

async function handleNewPosts(e) {
    const postsLimit = e.target.dataset.posts;
    const loadItems = e.target.dataset.load;
    const morePostsLink = e.target.dataset.uri;
    const postContainer = document.querySelector('.newposts');
    const visiblePosts = postContainer.querySelectorAll('.card-wrapper:not(.d-none)');
    if (visiblePosts.length < (postsLimit - 1)) {
        const hiddenPosts = postContainer.querySelectorAll('.card-wrapper.d-none');
        for (const hiddenPost of hiddenPosts) {
            hiddenPost.classList.remove('d-none');
            hiddenPost.classList.remove('d-md-block');
        }
    } else {
        const url = morePostsLink + '?offset=' + postsLimit + '&limit=' + loadItems;
        const response = await fetch(url, {method: 'GET'})
            .then(response => response.json());
        const newPosts = response.result;
        e.target.dataset.posts = parseInt(postsLimit) + newPosts.length;
        for (const newPost of newPosts) {
            postContainer.innerHTML += newPost;
        }
    }
    e.target.blur();
}
