import * as Ui from "../JavaScript/ui";
import {Dropdown} from "bootstrap";

const activeClassName = 'active';
const toggleButton = document.getElementById('search-toggle');
const searchFormWrapperElement = document.getElementById('search-form-wrapper');
const searchInputs = document.querySelectorAll('input[type="search"]');
const completionLists = document.querySelectorAll(".searchcompletion");
const buttonSearchClear = document.getElementById('button-search-clear');

if (toggleButton) {
    toggleButton.addEventListener('click', toggleSearchForm);
}

searchFormWrapperElement.addEventListener('click', function(e) {
    e.stopPropagation();
});

for (let i = 0; i < searchInputs.length; i++) {
    if (searchInputs[i].value.length > 0) {
        toggleButtonSearchClear(true);
    }
    searchInputs[i].addEventListener('keyup', function (e) {
        handleSearchInput(e);
    });
}

if (buttonSearchClear) {
    buttonSearchClear.addEventListener('click', clearSearch);
}

window.addEventListener('click', function () {
    const activeDropdown = document.querySelector('#mainnav .dropdown-toggle.show');
    if (!activeDropdown) {
        closeSearchForm();
    }
});

export function toggleSearchForm(searchPage = false) {
    const activeMenuDropdownToggle = document.querySelector('#mainnav .dropdown-toggle.show');
    if (activeMenuDropdownToggle) {
        Dropdown.getOrCreateInstance(activeMenuDropdownToggle).hide();
    }
    const searchFormInputElement = document.querySelector('#search-form-wrapper input');
    if (searchFormWrapperElement.classList.contains(activeClassName)) {
        searchFormWrapperElement.classList.remove(activeClassName);
        toggleButton.classList.remove(activeClassName);
        clearCompletionDesktop();
    } else {
        searchFormWrapperElement.classList.add(activeClassName);
        toggleButton.classList.add(activeClassName);
        searchFormInputElement.focus();
        searchFormInputElement.select();
        if (searchFormInputElement.value.length > 0 && !searchPage) {
            getSuggestion(searchFormInputElement);
        }
    }
}

function closeSearchForm() {
    if (searchFormWrapperElement.classList.contains(activeClassName)) {
        toggleSearchForm();
    }
    Ui.hideMenuOverlay();
}

async function getSuggestion(searchInput) {
    if (searchInput.value === '') return;
    const url = searchInput.dataset.autocompleteSource + '&term=' + searchInput.value;
    const respose = await fetch(url, {method: 'GET'})
        .then(response => response.json())
        .then(data => {
            for (let i = 0; i < completionLists.length; i++) {
                completionLists[i].innerHTML = null;
                const listContainer = document.createElement('ul');
                const suggestContainer = document.createElement('ul');
                listContainer.classList.add('list-unstyled');
                listContainer.classList.add('autocomplete');
                suggestContainer.classList.add('list-unstyled');
                suggestContainer.classList.add('suggest');
                for (let i = 0; i < data.completions.length; i++) {
                    const listItem = document.createElement('li');
                    const completionArr = [];
                    const completionText = data.completions[i];
                    const substrStart = completionText.indexOf(searchInput.value.toLowerCase());
                    const substrEnd = substrStart + searchInput.value.length;
                    if (substrStart > 0) {
                        completionArr.push('<b>' + completionText.slice(0, substrStart) +  '</b>');
                    }
                    if (substrStart > -1) {
                        completionArr.push(completionText.slice(substrStart, substrEnd));
                    }
                    if (substrEnd < (completionText.length - 1)) {
                        completionArr.push('<b>' + completionText.slice(substrEnd, completionText.length) +  '</b>');
                    }
                    listItem.innerHTML += completionArr.join('');
                    listItem.addEventListener('click', () => {
                        completionToSearch(completionText);
                    });
                    listContainer.appendChild(listItem);
                }
                if (data.suggestions.length > 0) {
                    const listItem = document.createElement('li');
                    const listItemTitle = document.createTextNode('Blogbeiträge:');
                    listItem.appendChild(listItemTitle);
                    listItem.classList.add('fw-bold');
                    suggestContainer.appendChild(listItem);
                }
                for (let i = 0; i < data.suggestions.length; i++) {
                    const listItem = document.createElement('li');
                    const listItemLink = document.createElement('a');
                    const listItemImage = document.createElement('img');
                    const listItemTitle = document.createTextNode(data.suggestions[i].title);
                    listItemImage.setAttribute('src', data.suggestions[i].imageUrl);
                    listItemImage.setAttribute('height', '50');
                    listItemLink.setAttribute('href', data.suggestions[i].nodeUrl);
                    listItemLink.classList.add('d-flex');
                    listItemLink.classList.add('searchcompletion-suggest');
                    listItemLink.appendChild(listItemImage);
                    listItemLink.appendChild(listItemTitle);
                    listItem.appendChild(listItemLink);
                    suggestContainer.appendChild(listItem);
                }
                if (data.completions.length > 0) {
                    completionLists[i].appendChild(listContainer);
                }
                if (data.suggestions.length > 0) {
                    completionLists[i].appendChild(suggestContainer);
                }
                Ui.showMenuOverlay();
            }
        });
}

function handleSearchInput(e) {
    const lastValue = e.target.dataset.lastValue;
    e.target.dataset.lastValue = e.target.value;
    if (e.target.value.length > 0) {
        toggleButtonSearchClear(true);
    } else {
        toggleButtonSearchClear(false);
    }
    if (e.target.value.length > 2 && e.target.value !== lastValue) {
        getSuggestion(e.target);
    } else {
        for (let i = 0; i < completionLists.length; i++) {
            completionLists[i].innerHTML = null;
        }
    }
}

function completionToSearch(completion) {
    for (let i = 0; i < searchInputs.length; i++) {
        searchInputs[i].value = completion;
    }
    searchInputs[0].form.submit();
}

function toggleButtonSearchClear(isActive) {
    if (!buttonSearchClear) return;
    if (isActive) {
        buttonSearchClear.classList.add(activeClassName);
    } else {
        buttonSearchClear.classList.remove(activeClassName);
    }
}

function clearSearch() {
    toggleButtonSearchClear(false);

    for (let i = 0; i < searchInputs.length; i++) {
        searchInputs[i].value = '';
        searchInputs[i].setAttribute('value', '');
    }

    for (let i = 0; i < completionLists.length; i++) {
        completionLists[i].innerHTML = '';
    }

    buttonSearchClear.previousElementSibling.focus();
}

export function clearCompletionDesktop() {
    const completionDesktop = document.querySelector('#mainnav .searchcompletion');
    completionDesktop.innerHTML = '';
    Ui.hideMenuOverlay();
}
